<template>
  <div
    class="nav-link waves-effect waves-light nav-user"
    :class="{ 'dropdown-toggle': nameHidden }"
    v-if="auth"
    v-click-outside="close"
  >
    <div class="position-absolute" style="top: -5px; width: 50px; cursor: pointer;" v-if="viewNewYear" @click="toggleUserMenu">
      <img src="@/assets/new_year_2024/menu_hat.svg" class="w-100 h-auto" alt="">
    </div>
    <div
      class="user-menu__toggle"
      style="cursor: pointer"
      @click="toggleUserMenu"
      :class="[userMenu ? 'open' : '']"
    >
      <img
        v-if="showAvatar"
        class="avatar-small"
        :src="avatar ? avatar : require('@/assets/icons/user/user.svg')"
      />
      <img v-else src="./../../static/icon/user.svg" />
      <!--<span class="user-menu__toggle-title">{{ user.name }}</span>-->
      <!-- <span class="ml-1" v-if="nameHidden">{{user.name}} <i class="mdi mdi-chevron-down" ></i> </span> -->
    </div>
    <ul
      class="dropdown-menu dropdown-menu-right profile-dropdown show"
      v-if="userMenu"
    >
      <!-- <li class="dropdown-item notify-item">
        {{ user.name }}
      </li> -->
      <li class="dropdown-item notify-item">
        {{ user.email }}
      </li>
      <li>
        <router-link
          :to="{ name: 'user', params: { id: user.id } }"
          class="dropdown-item notify-item"
          ><i class="ti-user"></i>Профиль</router-link
        >
      </li>
      <li>
        <a href="#" class="dropdown-item notify-item" @click.prevent="logout"
          ><i class="ti-power-off"></i>Выйти</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { wbConnect } from "@/_helpers/wb-connect.js";
export default {
  props: {
    nameHidden: {
      type: Boolean,
      default: true,
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.$store.dispatch("avatar/getAvatar");
  },
  computed: {
    ...mapState(["userMenu"]),
    ...mapGetters({
      user: "user/user",
      auth: "auth/auth",
      avatar: "avatar/avatar",
      viewNewYear: "new_year_store/viewNewYear",
    }),
  },
  methods: {
    logout() {
      const userID = JSON.parse(localStorage.getItem('user')).id;

      wbConnect().echoCloseChannel(`notification-${userID}`);
      this.$store.commit('user_notifications/SET_IS_VISIBLE_NOTIFICATIONS_POPUP', false);
      this.$store.commit('user_notifications/CLEAN_LIST_NOTIFICATIONS', false);
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "login" });
      
    },
    toggleUserMenu() {
      this.$store.dispatch("show_user_menu");
    },
    close() {
      if (this.userMenu) this.$store.dispatch("close_user_menu");
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-small {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.user-menu__toggle {
  height: 32px;
  width: 32px;
  border: 2px solid white;
  border-radius: 50%;
}
.nav-link {
  position: relative;
  padding: 9px 0;
  height: 100%;
}

.dropdown-toggle::after {
  display: none;
}
.profile-dropdown {
  width: 235px;
}
</style>