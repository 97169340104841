<template>
  <div class="select-wrapper" v-click-outside="closeSelect">
    <div class="select-field" @click="openSelect">
      <div class="select-value">{{ selectedOption }}</div>
      <img class="select-arrow" src="@/assets/restyle/images/shared/icons/arrow-down-gray.svg" alt="" />
    </div>
    <div v-if="isSelectOpen" class="select-options-wrapper">
      <div
        v-for="(option, index) in options"
        @click="selectOption(option)"
        class="select-option"
        :key="index"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SelectSmallNumbers',
    props: {
      options: {
        type: Array,
        required: true,
      },
      selectedOption: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        isSelectOpen: false,
      }
    },
    methods: {
      openSelect() {
        this.isSelectOpen = true;
      },
      selectOption(option) {
        this.$emit('select', option);
        this.closeSelect();
      },
      closeSelect() {
        this.isSelectOpen = false;
      }
    },
  }
</script>

<style lang="scss" scoped>
  .select-field {
    padding: 5px 5px 5px 12px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    gap: 6px;
    border: 1px solid #D1D9E6;
    border-radius: 5px;
    width: 50px;
    height: 25px;
    cursor: pointer;
  }
  .select-arrow {
    width: 12px;
    height: 8px;
  }
  .select-options-wrapper {
    position: absolute;
    bottom: -30px;
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid $color-smartro-green-bright;

  }
  .select-wrapper {
    position: relative;
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #4F4B85;
  }
  .select-option {
    cursor: pointer;
    width: 50px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: #FFF;
      background-color: $color-page-text;
    }
    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

</style>