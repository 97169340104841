import { render, staticRenderFns } from "./SelectSmallNumbers.vue?vue&type=template&id=c6a5ecf2&scoped=true"
import script from "./SelectSmallNumbers.vue?vue&type=script&lang=js"
export * from "./SelectSmallNumbers.vue?vue&type=script&lang=js"
import style0 from "./SelectSmallNumbers.vue?vue&type=style&index=0&id=c6a5ecf2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6a5ecf2",
  null
  
)

export default component.exports