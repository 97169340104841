var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reference-block"},[(_vm.userCan('monitoring_read'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"fact-online_monitoring"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: _vm.sizeOnlineMonitoring }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('online_monitoring'))+" "),(_vm.isActiveHotSpot('online_monitoring')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('online_monitoring'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('monitoring_read'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"fact-online_photo"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'online_monitoring_photo' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('online_monitoring_photo'))+" "),(_vm.isActiveHotSpot('online_monitoring_photo')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('online_monitoring_photo'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('vehicle_read_all') || _vm.back_office)?_c('div',{staticClass:"reference-block-link",attrs:{"id":"fact-vehicle"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'vehicle' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('vehicle'))+" "),(_vm.isActiveHotSpot('vehicle')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('vehicle'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('online_list'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"fact-online_list"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'online_list' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('online_list'))+" "),(_vm.isActiveHotSpot('online_list')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('online_list'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),_c('div',{staticClass:"reference-block-link",attrs:{"id":"fact-monitoring_vehicle"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'monitoring_vehicle' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('monitoring_vehicle'))+" "),(_vm.isActiveHotSpot('monitoring_vehicle')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('monitoring_vehicle'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1),(_vm.hoverEvent)?_c('RestyleTooltip',{attrs:{"hoverEvent":_vm.hoverEvent,"tooltipTopShift":5,"tooltipText":_vm.tooltipText}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }