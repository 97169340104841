import {request} from '../_helpers'
import {router} from '@/_helpers/router';

const create_cookie = (token) => {
    let date = new Date(Date.now() + 86400e3);
    date = date.toUTCString();

    return !Boolean(process.env.VUE_APP_LOCAL_DEV)? `auth_token=${token};expires=${date};Max-Age=82800;path=/;secure=true;samesite=none;domain=smartro.ru;`: `auth_token=${token};expires=${date};Max-Age=82800;path=/;secure=true;samesite=none;`
}

export const auth = {
  namespaced: true,
  state: {
    status: '',
    auth: localStorage.getItem('auth_token'),
    send: false,
  },
  getters: {
    auth: (state) => state.auth,
    send: (state) => state.send,
  },
  actions: {
    send({ commit }, payload) {
      commit(`send`, payload);
    },
    login({ commit, dispatch }, user) {
      request(`${process.env.VUE_APP_AUTH_URL}/login`, 'post', user)
        .then((response) => {
          localStorage.removeItem('logout');
          if (response.success) {
            window.document.cookie = create_cookie(response.data.token);
            localStorage.setItem('auth_token', response.data.token);
            commit('authSuccess');
            commit('auth', true);
            dispatch('send', false);
            dispatch('user/setUser', null, { root: true });
            dispatch('setInfoUserDisplay');

            let url = new URL(window.location.href);
            let redirect = url.searchParams.get('redirect');

            if (redirect) {
              router.push(redirect);
            } else {
              router.push({ name: 'home' });
            }
          } else {
            dispatch(
              'alert/alerts',
              { type: 'danger', message: response.message },
              { root: true }
            );
          }
        })
        .catch((error) => {
          dispatch('send', false);
        });
    },
    logout(store) {
      window.document.cookie = !Boolean(process.env.VUE_APP_LOCAL_DEV)
        ? 'auth_token=;Max-Age=-1;path=/;secure=true;samesite=none;domain=smartro.ru;'
        : 'auth_token=;Max-Age=-1;path=/;secure=true;samesite=none;';
      localStorage.removeItem('monitoring_organisation_id');
      if (localStorage.getItem('auth_token'))
        request(`${process.env.VUE_APP_AUTH_URL}/logout`, 'post').then(() => {
          store.dispatch('user/removeUser', null, { root: true });
          localStorage.removeItem('auth_token');
          store.commit('auth', false);
        });
      localStorage.setItem('logout', '1');
    },
    forgot(store, email) {
      return new Promise((resolve) => {
        request(`${process.env.VUE_APP_AUTH_URL}/password/email`, 'post', {
          email: email,
        }).then((response) => {
          resolve(response);
        });
      });
    },
    reset(store, data) {
      return new Promise((resolve) => {
        request(
          `${process.env.VUE_APP_AUTH_URL}/password/reset`,
          'post',
          data
        ).then((response) => {
          resolve(response);
        });
      });
    },
    setInfoUserDisplay() {
      const screenUserSize = {
        width: String(screen.width),
        height: String(screen.height),
      };
      const windowOpenSize = {
        width: String(window.innerWidth),
        height: String(window.innerHeight),
      };
      const data = {
          screen_default_user_size: screenUserSize,
          screen_open_user_size: windowOpenSize,
        }

      const token = localStorage.getItem('auth_token');
      if (!token) {
        console.error('No authentication token found');
        return;
      }

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      request(`${process.env.VUE_APP_WN}/devices`, 'post', {
        data: data,
      })
        .then((response) => {
        })
        .catch((error) => {
            console.error('Error while sending device info:', error);
        });
    },
  },
  mutations: {
    send(store, payload) {
      store.send = payload;
    },
    authSuccess(state) {
      state.status = 'success';
    },
    authError(state) {
      state.status = 'error';
    },
    logoutSuccess(state) {
      state.status = 'logout';
    },
    logoutError(state) {
      state.status = 'error';
    },
    auth(state, auth) {
      state.auth = auth;
    },
  },
};
