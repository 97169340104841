import { request } from '../_helpers';

export const hotspots = {
  namespaced: true,
  state: {
    listActiveHotSpots: [],
    hotSpotRoutes: [],
    listAllHotSpots: [],
  },
  getters: {
    allListActiveHotSpots: (state) => {
      return state.listActiveHotSpots;
    },
    listAllHotSpots: (state) => {
      return state.listAllHotSpots;
    },
    getHotSpotRoutes: (state) => {
      return state.hotSpotRoutes;
    },
    getByHotSpotRouteTitleMenu: (state) => (name) => {
      return state.hotSpotRoutes.find((item) => item.routeName === name)
        ?.titleMenu;
    },
  },
  actions: {
    getListActiveHotSpots(store) {
      request(`${process.env.VUE_APP_WN}/hotspot/list_active`, 'post').then(
        (result) => {
          store.commit('listActive', result.data);
        }
      );
    },
    getListAllHotSpots(store) {
      request(`${process.env.VUE_APP_WN}/hotspot/list_all`, 'post').then(
        (result) => {
          store.commit('listAll', result.data);
        }
      );
    },
  },
  mutations: {
    listActive(state, list) {
      state.listActiveHotSpots = list;
    },
    listAll(state, list) {
      state.listAllHotSpots = list;
    },
    setHotSpotRoutes(state, routes) {
      state.hotSpotRoutes = [...state.hotSpotRoutes, ...routes];
    },
  },
};
