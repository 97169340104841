import { render, staticRenderFns } from "./fact.vue?vue&type=template&id=9cb0817e&scoped=true"
import script from "./fact.vue?vue&type=script&lang=js"
export * from "./fact.vue?vue&type=script&lang=js"
import style0 from "./fact.vue?vue&type=style&index=0&id=9cb0817e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cb0817e",
  null
  
)

export default component.exports