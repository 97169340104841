var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reference-block scrollbar scrollbar--secondary"},[(_vm.userCan('location_show'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"logistic-polygon"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'polygon' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('polygon'))+" "),(_vm.isActiveHotSpot('polygon')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('polygon'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),_vm._l((_vm.urlLogistick),function(item,index){return _c('div',{key:index,staticClass:"reference-block-link",attrs:{"id":`logistic-url-${1 + index}`}},[_c('a',{attrs:{"target":"_blank","href":item?.url}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(item?.name)+" "),(_vm.isActiveHotSpot(item?.name)?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot(item?.name),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])])}),(_vm.hoverEvent)?_c('RestyleTooltip',{attrs:{"hoverEvent":_vm.hoverEvent,"tooltipTopShift":5,"tooltipText":_vm.tooltipText}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }