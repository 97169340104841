<template>
  <div class="date-component-container">
    <v-date-picker
      class="restyle-date-range-picker"
      is-range
      :value="dateRange"
      @input="inputHandler($event)"
      @dayclick="$emit('dayclick', $event)"
      @popoverDidShow="isDatePickerOpen = true; $emit('isDatePickerOpen', true)"
      @popoverDidHide="isDatePickerOpen = false; $emit('isDatePickerOpen', false)"
      :model-config="{ type: 'string', mask: 'DD.MM.YYYY' }"
      :min-date="minDate"
      :max-date="maxDate"
      :disabled-dates="disabledDates"
      @drag="$emit(`dragValue`, $event)"
      v-click-outside="outside"
    >
      <template v-slot="{ togglePopover }">
        <div
          class="date-display"
          :style="dateSelectBoxStyle"
          :class="{ 'datepicker-open': isDatePickerOpen, 'date-picked': dateRange, 'is-error': isError }"
          @click="togglePopover"
          @mouseenter="isHovered = true"
          @mouseleave="isHovered = false"
        >
          <span class="date-display-left">
            <img
              v-if="!dateRange"
              class="search-icon-default"
              src="@/assets/restyle/images/shared/icons/filter_search_default.svg"
              alt=""
            />
            <img
              v-if="dateRange && canClearDate"
              @click.stop="dateRange = null"
              src="@/assets/restyle/images/shared/icons/cross_2.svg"
              class="cross"
              alt=""
            >
            <span
              v-if="!dateRange"
              class="placeholder"
            >
              <slot name="placeholder">
                Дата
              </slot>
            </span>
            <span class="date-value" :class="{ 'date-value-gray': isDatePickerOpen }">
              {{ dateInputValue }}
            </span>
          </span>
          <span class="date-display-right">
            <img src="@/assets/restyle/images/shared/icons/date/datepicker.svg" alt=""/>
          </span>
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
  export default {
    name: 'DateRangePicker',
    props: {
      dates: {
        default: null,
      },
      dateSelectBoxStyle: {
        default: '',
      },
      minDate: {
        default: '',
      },
      maxDate: {
        default: '',
      },
      canClearDate: {
        type: Boolean,
        default: true,
      },
      disabledDates: {
        type: Array,
        default: () => []
      },
      isError: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        dateRange: null,
        isHovered: false,
        isDatePickerOpen: false,
      }
    },
    created() {
      this.dateRange = this.dates ? this.dates : null;
     },
    computed: {
      dateInputValue() {
        if (this.dates?.start && this.dates?.end) {
          return `${this.dates.start.split('-').join('.')} - ${this.dates.end.split('-').join('.')}`;
        } else {
          return '';
        }
      }
    },
    watch: {
      dates(newValue) {
        this.dateRange = newValue;
      }
    },
    methods: {
      inputHandler(dateValue) {
        this.$emit('dateChange', dateValue);
      },
      outside(event) {
        this.$emit('outside', event)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .date-display {
    position: relative;
    bottom: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    width: 360px;
    padding: 12px;
    box-shadow: 0px 2px 8px rgba(23, 21, 55, 0.1);
    border-radius: 5px;
    background-color: $color-restyle-white;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    cursor: pointer;
    &:hover {
      outline: 1px solid $color-smartro-green-bright;
    }
  }

  .date-display-left {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .date-picker {
    color: $color-page-text !important;
  }

  .date-component-container {
    position: relative;
    top: 10px;
  }

  .placeholder {
    color: $color-gray-text;
  }

  .placeholder-active {
    color: $color-page-text;
  }

  .cross {
    width: 15px;
    height: 15px;
  }

  .datepicker-open {
    outline: 1px solid $color-smartro-green-bright;
  }

  .date-value-gray {
    color: $color-gray-text;
  }

  .date-display-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .date-picked {
    outline: 1px solid #466ABF;
  }

  .is-error {
    outline: 1px solid #EB6363 !important;
  }

</style>